export default {
    demandesacces: {
        application: {
            title: 'Application',
        },
        credentials: {
            created_at: 'Date de la demande',
        },
        custom_column: 'Gestion des droits',
        index: 'Liste des demandes d\'accès',
        response: {
            deny: 'Aucun accès',
            manage: 'Gestion',
            read: 'Lecture',
            write: 'Écriture',
        },
        text: 'Motivation',
        toaster: {
            PUT: {
                success: {
                    text: 'La demande d\'accès a été traitée',
                    title: 'Demande d\'accès',
                },
            },
        },
        user: {
            name: 'Utilisateur',
        },
    },
    structures: {
        add: 'Créer une structure',
        communes: {
            insee: 'Commune',
        },
        communes_count: 'Communes',
        delete: {
            index: 'Supprimer une structure',
            text: 'Souhaitez-vous vraiment supprimer la structure "{name}" ? <br/> <ul class="pl-2 pt-2"> <li>La suppression est <b>irrévocable</b>.</li><li><b>Toutes les données</b> renseignées dans les applications seront supprimées.',
            title: 'Supprimer la structure <span class="text-caption text-medium-emphasis">{name}</span>',
        },
        edit: 'Modifier la structure <span class="text-caption text-medium-emphasis">{name}</span>',
        index: 'Liste des structures',
        name: 'Nom',
        toaster: {
            POST: {
                success: {
                    text: 'La structure {name} a bien été créé',
                    title: 'Structures',
                },
            },
            PUT: {
                success: {
                    text: 'La structure {name} a bien été mise à jour',
                    title: 'Structures',
                },
            },
            DELETE: {
                success: {
                    text: 'La structure a bien été supprimée',
                    title: 'Structure supprimée',
                },
            },
        },
        type: {
            AUTRE: 'Autre',
            COMMUNE: 'Commune',
            DEPARTEMENT: 'Département',
            EPCI: 'EPCI',
            PRIVE: 'Privé',
            SYNDICAT: 'Syndicat',
            index: 'Type',
        },
        users: {
            id: 'Utilisateur',
        },
        users_count: 'Utilisateurs',

        view: 'Visualiser la structure <span class="text-caption text-medium-emphasis">{name}</span>',
    },
    utilisateurs: {
        add: 'Ajouter un utilisateur',
        application_roles_count: 'Applications',
        having_application_access: 'Application',
        credentials: {
            created_at: 'Compte créé le',
            updated_by: {
                name: 'Modifié par',
            },
        },
        delete: {
            index: 'Supprimer l\'utilisateur',
            text: 'Souhaitez-vous vraiment supprimer l\'utilisateur "{name}" ? <br/> <ul class="pl-2 pt-2"> <li>La suppression est <b>irrévocable</b>.</li><li><b>Toutes les données</b> renseignées par l\'utilisateur dans les applications seront anonymisées.',
            title: 'Supprimer un utilisateur <span class="text-caption text-medium-emphasis">{name}</span>',
        },
        edit: 'Modifier un utilisateur <span class="text-caption text-medium-emphasis">{first_name} {last_name}</span>',

        export: 'Exporter les utilisateurs',
        has_trashed: {
            all: 'Tous les utilisateurs',
            false: 'Exclure les désactivés',
            index: 'Statut du compte',
            true: 'Seulement les désactivés',
        },
        index: 'Liste des utilisateurs',
        last_connexion: 'Dernière connexion',
        name: 'Nom',
        restore: 'Activer',
        toaster: {
            DELETE: {
                success: {
                    text: 'L\'utilisateur {name} a bien été désactivé',
                    title: 'Utilisateur désactivé',
                },
            },
            POST: {
                success: {
                    text: 'L\'utilisateur {name} a bien été créé',
                    title: 'Utilisateur sauvegardé',
                },
            },
            PUT: {
                success: {
                    text: 'L\'utilisateur {name} a bien été mis à jour',
                    title: 'Utilisateur sauvegardé',
                },
            },
        },
        view: 'Visualiser un utilisateur <span class="text-caption text-medium-emphasis">{first_name} {last_name}</span>',
        managed_organizations: 'Structures gérées',
        can_manage_all: 'Toutes les structures',
        updated_at: 'Date de modification',
    },
    applications: {
        index: 'Applications',
        edit: 'Modifier l\'application',
        add: 'Ajouter une application',
        title: 'Application',
        users_count: 'Utilisateurs',
        users: {
            id: 'Utilisateurs',
        },
        description: 'Description',
        html: 'Texte d\'accueil',
        slug: 'Identifiant unique d\'URL',
        show_in_portal: {
            index: 'Afficher dans le portail Pildom',
            true: 'Oui',
            false: 'Non',
            all: 'Toutes les applications',
        },
        access_requests_count: {
            index: 'Demandes en attente',
            true: 'Oui',
            false: 'Non',
            all: 'Toutes les demandes',
        },
        delete: {
            index: 'Supprimer l\'application',
            text: 'Souhaitez-vous vraiment supprimer l\'application "{title}" ? <br/> <ul class="pl-2 pt-2"> <li>La suppression est <b>irrévocable</b>.</li><li><b>Toutes les demandes d\'accès</b> seront <b>supprimées</b>.<li><b>Toutes les données</b>renseignées dans l\'application seront <b>supprimées</b>.',
            title: 'Supprimer une application <span class="text-caption text-medium-emphasis">{title}</span>',
        },
    },
    couches: {
        index: 'Liste des couches cartographiques',
    },
    gestioncouches: {
        index: 'Liste des couches cartographiques',
        add: 'Ajouter une couche',
        edit: 'Modifier une couche',
        view: 'Visualiser la couche',
        title: 'Couche cartographique',
        delete: {
            index: 'Supprimer la couche',
            text: 'Souhaitez vous supprimer cette couche ?',
            title: 'Supprimer couche',
        },
        code: 'Nom',
        url: 'Url',
        base_layer: 'Fond de plan',
        toaster: {
            DELETE: {
                success: {
                    text: 'La couche {name} a bien été désactivée',
                    title: 'Couche supprimée',
                },
            },
            POST: {
                success: {
                    text: 'La couche {name} a bien été créée',
                    title: 'Couche sauvegardée',
                },
            },
            PUT: {
                success: {
                    text: 'La couche {name} a bien été mise à jour',
                    title: 'Couche sauvegardée',
                },
            },
        },
    },
    availableslayers: 'Liste des couches cartographiques',
}
